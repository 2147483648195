import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import SwatchGroup from "../components/molecules/swatch-group";

function DesignSwatchGroup() {
  return (
    <div className="design__content">
      <SEO title="Design System - Swatch Group" />
      <h2>Swatch Group</h2>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Swatch Group</h5>
          <DesignStory
            type="component"
            component={SwatchGroup}
            props={{
              options: {
                name: "color",
                values: [
                  "Light Blue",
                  "Dark Blue",
                  "Blush",
                  "Light Blue Striped",
                  "Natural Contrast",
                  "Purple",
                  "Brown",
                  "Red",
                  "Navy Blue",
                  "Cream",
                  "Yellow",
                  "Blush Striped",
                ],
              },
              minOverflow: 11,
              size: "large",
              colorInfo: [
                {
                  node: {
                    shopifyColorOption: {
                      color: "Light Blue",
                    },
                    hexValue: "cad3e0",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Dark Blue",
                    },
                    hexValue: "c0cde2",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Blush",
                    },
                    hexValue: "ffe8dd",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Light Blue Striped",
                    },
                    hexValue: "cad3e0",
                    pattern: "striped",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Natural Contrast",
                    },
                    hexValue: "E5E2D5",
                    hexValue2: "DBD5BB",
                    pattern: "contrast-dominant",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Purple",
                    },
                    hexValue: "BAB6CB",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Brown",
                    },
                    hexValue: "BB755E",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Red",
                    },
                    hexValue: "BA2D24",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Navy Blue",
                    },
                    hexValue: "1E2351",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Cream",
                    },
                    hexValue: "FFFCED",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Yellow",
                    },
                    hexValue: "FFFAE3",
                  },
                },
                {
                  node: {
                    shopifyColorOption: {
                      color: "Blush Striped",
                    },
                    hexValue: "FBE5DC",
                    pattern: "striped",
                  },
                },
              ],
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps
            component={SwatchGroup}
            title="Swatch Group Properties"
          />
        </div>
      </div>
    </div>
  );
}

export default DesignSwatchGroup;
